import React from "react"
import {useNavigate } from "react-router-dom"
import Logo from "../../Assets/Images/Logo.png"
import { CONSTANTS } from "../../Helpers/Constants"
import "./header.css"

export default function Header() {
    const [activeTab, setActiveTab] = React.useState('home')
    const navigate = useNavigate()

    React.useEffect(() => {
        const currentPage = window.location.pathname
        if (currentPage.split("/")[1]) setActiveTab(currentPage.split("/")[1])
    }, [])
    const tabHandler = (tabName) => {
        const link = !tabName ? activeTab : `/${tabName}`
        setActiveTab(tabName);
        navigate(link)
    }
    return (
        <div className="insh-header insh-wrapper">
            <div onClick={()=>navigate("/")} className="insh-logo-container">
                <img src={Logo} alt="logo" />
            </div>
            <div className="insh-menu">
                <ul>
                    <li
                        className={
                            `${activeTab === CONSTANTS.GENERAL.HOME_TXT.toLocaleLowerCase() && "active"}`}
                        onClick={() => tabHandler(CONSTANTS.GENERAL.HOME_TXT.toLocaleLowerCase())}
                    >
                        {CONSTANTS.GENERAL.HOME_TXT}
                    </li>
                    <li
                        className={`${activeTab === CONSTANTS.GENERAL.DARULIFTA_TXT.toLocaleLowerCase() && "active"}`}
                        onClick={() => tabHandler(CONSTANTS.GENERAL.DARULIFTA_TXT.toLocaleLowerCase())}
                    >
                        {CONSTANTS.GENERAL.DARULIFTA_TXT}
                    </li>
                    <li
                        className={`${activeTab === CONSTANTS.GENERAL.ARTICLES_TXT.toLocaleLowerCase() && "active"}`}
                        onClick={() => tabHandler(CONSTANTS.GENERAL.ARTICLES_TXT.toLocaleLowerCase())}
                    >
                        {CONSTANTS.GENERAL.ARTICLES_TXT}
                    </li>
                    <li
                        className={`${activeTab === CONSTANTS.GENERAL.VIDEOS_TXT.toLocaleLowerCase() && "active"}`}
                        onClick={() => tabHandler(CONSTANTS.GENERAL.VIDEOS_TXT.toLocaleLowerCase())}
                    >
                        {CONSTANTS.GENERAL.VIDEOS_TXT}
                    </li>
                    <li
                        className={`${activeTab === CONSTANTS.GENERAL.CONTACT_TXT.toLocaleLowerCase() && "active"}`}
                        onClick={() => tabHandler(CONSTANTS.GENERAL.CONTACT_TXT.toLocaleLowerCase())}
                    >
                        {CONSTANTS.GENERAL.CONTACT_TXT}
                    </li>
                    <li
                        className={`${activeTab === CONSTANTS.GENERAL.ABOUT_TXT.toLocaleLowerCase() && "active"}`}
                        onClick={() => tabHandler(CONSTANTS.GENERAL.ABOUT_TXT.toLocaleLowerCase())}
                    >
                        {CONSTANTS.GENERAL.ABOUT_TXT}
                    </li>
                </ul>
            </div>
            <div className="insh-donate-btn insh-btn">
                <button>{CONSTANTS.GENERAL.SPONSOR_TXT}</button>
            </div>
        </div>
    )
}