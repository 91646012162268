import React from "react";
import { CONSTANTS } from "../../Helpers/Constants";
import { VIDEOS } from "../../Helpers/Videos";
import VideosView from "./Views/VideosView";
import "./Views/Videos.css"

export default function Videos(){
    const [videosList, setVideosList] = React.useState(VIDEOS)
    const categories = CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES

    const categoryHandler = (selectedCategory) => {
        if (selectedCategory === CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.ALL_VIDEOS){
            setVideosList(VIDEOS)
        } else {
            const tempVideoData = []
            VIDEOS.forEach(video => selectedCategory.toLocaleLowerCase() === video.category.toLocaleLowerCase() && tempVideoData.push(video))
            setVideosList(tempVideoData)
        }
    }

    const propsList = {
        videosList,
        setVideosList,
        categories,
        categoryHandler
    }
    return (
        <>
            <VideosView
                propsList={propsList}
            />
        </>
    )
}