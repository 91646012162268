import { CONSTANTS } from "../../../Helpers/Constants";
// import featureImage from "../../../Assets/Images/masjid-bg.png";

export default function HomeHeading(){
    return (
        <div className="home-heading">
            <div className="website-name">
                <h1>
                    {CONSTANTS.GENERAL.WEBSITE_NAME}
                </h1>
                <p>
                    {CONSTANTS.GENERAL.WEBSITE_SLOGAN}
                </p>
            </div>
        </div>
    )
}