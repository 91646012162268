import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import VideosList from "./VideosList";

export default function VideosView({ propsList }) {
    return (
        <>
            <Header />
            <VideosList
                propsList={propsList}
            />
            <Footer/>
        </>
    )
}