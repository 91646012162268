export const FATAWA_ANSWERS = {
    answer_01: <>
        <p className="new-para">
            مسلمان کی یہ شان ہونی چاہیئے کہ اپنی مشغولیت ان امور میں رکھے، جو دینی یا دنیاوی اعتبار سے فائدہ مند ہوں، جبکہ ایسے کاموں سے اجتناب کرنا ہی عظمتِ مسلم ہے،جو بے کار اور بے نفع ہوں۔ البتہ صحیح مصلحت کے پیشِ نظر، نصف سینے تک تصاویر یا کارٹون بنانا جائز ہے۔</p>

        <p className="new-para">
            جیسا کہ رحمتِ دو عالم صلی اللہ علیہ وسلم نے فرمایا،ِ
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                من حسن اسلام المرء ترکہ مالا یعنیہ</span>
            ۔ یعنی انسان کے اسلام کا حسن، فضولیات کو چھوڑنے سے ہے۔

            <span className="arabic brown-text">(السنن للترمذی:2317)</span></p>
        <p className="new-para">
            لہذا جاندار یا بے جان اشیاء کی شوقیہ تصاویر، جس کا کوئی حاصل نہیں، اس سے اجتناب کیا جائے گا کہ مکروہ و ناپسندیدہ عمل ہے اور جن صورتوں میں جاندار کی تصویریں بنانا ہی گناہ ہو، وہاں بچنا بھی لازم ہوگا۔
        </p>
        <p className="new-para">
            حضرتِ نضر بن انس رضی اللہ عنھما فرماتے ہیں،ِ
        </p>
        <p className="reference-gap">
            حضرت عبد اللہ ابنِ عباس رضی اللہ عنھما کی بارگاہ میں عراق سے ایک شخص نے حاضر ہو کر(کچھ تصاویر دکھاتے ہوئے)عرض کی کہ میں ایک عراقی شخص ہوں اور میں نے یہ تصاویر بنائی ہیں،تو حضرت عبد اللہ بن عباس رضی اللہ عنھمانے دو یا تین بار اُسے قریب آنے کا کہا۔ جب وہ قریب آیا، تو آپ رضی اللہ عنھما نے فرمایا،
            <span className="arabic brown-text">سمعت رسول اللہ صلی اللہ علیہ وسلم یقول من صور صورۃ فی الدنیا یکلف یوم القیامۃ ان ینفخ فیہ الروح ولیس بنافخ</span>
            ۔ یعنی میں نے رسول اللہ صلی اللہ علیہ وسلم کو فرماتے سنا کہ جس نے دنیا میں کوئی تصویر بنائی،تو روزِ قیامت اُسے اُسی تصویر میں روح پھونکنے کا مکلف بنایا جائے گا، حالانکہ وہ روح پھونکنے سے قاصر ہوگا(جس کے باعث اسے عذاب دیا جائے گا)۔
            <span className="arabic brown-text">(المسند لامام احمد بن حنبل:2162)</span>
        </p>
        <p className="new-para">
            مذکورہ حدیث میں بیان کردہ وعید حرمتِ تصاویر کی واضح دلیل ہے۔البتہ دیگر احادیث اور آثارِ صحابہ سے معلوم ہوتا ہے کہ حرمتِ تصاویر کی اصل بت پرستی کی روک تھام ہے،اسی وجہ سے غیر جاندار یاایسی چھوٹی تصاویر ممانعت سے خارج ہیں، جن کی عبادت نہیں کی جاتی۔
        </p>
        <p className="new-para">
            ایک شخص نے حضرت عبد اللہ ابن عباس رضی اللہ عنھما سے صنعتِ تصاویر کی اجازت چاہی،تو آپ نے فرمایا،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">ویحک ان ابیت الا ان تصنع فعلیک بھذا الشجر کل شیء لیس فیہ روح</span>۔
            اگر تم تصویریں بنانے سے باز نہ آؤ، تو تمہارے لیے خرابی ہے۔مگر تمہیں بنانا ہی پڑے،تو تم پر لازم ہے کہ (فقط) اس درخت یا ہر اس چیز کی (تصویر) بناسکتے ہو،جس میں روح نہیں۔
            <span className="brown-text arabic">(الصحیح للبخاری:2225)</span>ِ
        </p>
        <p className="new-para">
            علامہ علاؤ الدین ابو بکر کاسانی رحمۃ اللہ علیہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">اما صورۃ مالا حیاۃ لہ کالشجر ونحو ذلک فلا یوجب الکراھۃ لان عبدۃ الصور لایعبدون تمثال ما لیس بذی روح فلا یحصل التشبہ بھم</span>۔
            غیر جاندار مثلاً درخت اور اس کی ہم مثل چیزوں کی تصاویر میں کوئی کراہت نہیں،کیونکہ تصاویر کی عبادت کرنے والے، ایسی تصویروں کو نہیں پوجتے، جو غیر جاندار کی ہوں۔ چنانچہ ان سے مشابہت نہیں ہوتی۔
            <span className="brown-text arabic">(البدائع الصنائع۔جلد1۔صفحہ116)</span>ِ
        </p>
        <p className="new-para">
            علامہ برہان الدین محمود حنفی بخاری علیہ الرحمہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">و صورۃ الحیوان ان کانت صغیرۃ بحیث لاتبدو للناظرین من بعید لا یکرہ اتخاذھا والصلاۃ الیھا لان ھذا مما لا یعبد وقد اصح انہ کان علی خاتم ابی ھریرۃ رضی اللہ عنہ ذبابتان و کان علی خاتم ابی موسی الاشعری رضی اللہ عنہ کرکیان و کان علی خاتم دانیال علیہ السلام صورۃ الاسد</span>۔
            جاندار کی تصویر اگر اتنی چھوٹی ہو کہ دور سے دیکھنے والے پر ظاہر نہ ہو،تو اس تصویر کو اپنے پاس رکھنا اور اس کی جانب نماز پڑھنا مکروہ نہیں، کیونکہ یہ ایسی تصویر ہے، جسے پوجا نہیں جاتا اور صحیح طور پر یہ بات منقول ہے کہ حضرت ابو ہریرہ رضی اللہ عنہ کی انگوٹھی میں دو مکھیوں،حضرت ابو موسیٰ اشعری کی انگوٹھی میں دو سارس پرندے اور حضرتِ دانیال علیہ السلام کی انگوٹھی میں شیر کی تصویر بنی ہوئی تھی۔
            <span className="brown-text arabic">(المحیط البرھانی فی فقہ النعمانی۔جلد5۔صفحہ309)</span>ِ</p>
        <p className="new-para">
            اسی سبب سے تصاویر اگر مقامِ تہمت پر موجود ہوں، تو ان میں بھی حکمِ کراہت نہیں،کیونکہ اس میں عبادت کرنے والوں سے مشابہت کا کوئی اندیشہ نہیں۔ </p>
        <p className="new-para">
            حضرتِ ابو ہریرہ رضی اللہ عنہ سے مروی ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">استاذن جبریل علیہ السلام علی النبی صلی اللہ علیہ وسلم فقال ادخل فقال کیف ادخل وفی بیتک ستر فیہ تصاویر فاما ان تقطع رءوسھا او تجعل بساطا یوطا فانا معشر الملائکۃ لاندخل بیتا فیہ تصاویر</span>۔
            جبرئیل امین علیہ السلام نے نبی کریم صلی اللہ علیہ وسلم سے در اقدس میں داخلے کی اجازت چاہی،تو رسول اللہ صلی اللہ علیہ وسلم نے فرمایا کہ تشریف لے آئیں۔انہوں نے عرض کی: میں اندر کیسے آؤں، جبکہ آپ کے گھر میں تصویروں والا پردہ موجود ہے۔ البتہ اگر آپ اس تصویر کا سر کاٹ دیں یا اُسے پیروں میں روندنے والی چادر بنادیں(تو حرج نہیں)،کیونکہ ہم گروہِ ملائکہ ایسے گھر میں داخل نہیں ہوتے،جہاں تصاویر موجود ہوں۔
            <span className="brown-text arabic">(السنن للنسائی:5365)</span>ِ</p>
        <p className="new-para">
            مذکورہ دلائل سے یہ بات واضح ہوتی ہے کہ جاندار کی فقط ایسی تصاویر بنانا ہی شرعاً منع ہے، جسے پوجا جاتا ہو۔جبکہ ایسی تصاویر میں کوئی ممانعت نہیں،جس کی پرستش نہ کی جاتی ہو، جیسے غیر ذی روح یا انتہائی چھوٹی تصاویر جنہیں دور سے دیکھیں، تو اعضاء کی تفصیل واضح نہ ہو۔ اسی بات کو مدنظر رکھتے ہوئے، فقہاءِ کرام نے تصویر سے متعلق کچھ احکامات بیان فرمائے ہیں۔
        </p>
        <p className="new-para">
            سیدی اعلی حضرت امام احمد رضا خان علیہ الرحمہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            اگر اس (تصویر) کی حکایت، محکی عنہ(یعنی جس کی تصویر ہے،اس) میں حیات کا پتادے،یعنی ناظر یہ سمجھے کہ  گویا ذو التصویر (تصویر والے) زندہ کو دیکھ رہا ہے،تو وہ تصویر ذی روح کی ہے (اور ناجائز ہے) اور حکایتِ حیات نہ کرے،ناظر اس کے ملاحظہ سے جانے کہ یہ حی کی صورت نہیں، میت و بے روح کی ہے، تو وہ غیر ذی روح کی ہے (اور جائز ہے)۔
            <span className="brown-text urdu">(فتاوی رضویہ۔جلد24۔صفحہ587)</span>ِ</p>
        <p className="new-para">
            چونکہ مردے کی تصویروں کو پوجا نہیں جاتا، لہذا فقہاء نے ان کی اجازت مرحمت فرمائی۔حتی کہ فقہاء کرام نے ان تصاویر کی اجازت بھی مرحمت فرمائی، جس میں ان اعضاء کو محو کردیا جائے،جن کے بغیر زندگی ممکن نہیں۔
        </p>
        <p className="new-para">
            علامہ علاؤ الدین کاسانی علیہ الرحمہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">لایکرہ (لو کانت صغیرۃ) لاتتبین تفاصیل اعضائھا للناظر قائما وھی علی الارض ذکرہ الحلبی (او مقطوعۃ الراس او الوجہ) او ممحوۃ عضو لاتعیش بدونہ</span>۔
            (ایسی تصویر بنانے) میں کوئی کراہت نہیں، جو اتنی چھوٹی ہو کہ زمین پر رکھیں، تو کھڑے ہوکر دیکھنے والے پر اعضاء کی تفصیل ظاہر نہ ہویا اس کا سر، چہرہ یا ایسا عضو کٹا ہوا ہو، جس کے بغیر زندہ نہ رہ سکے۔
            <span className="brown-text arabic">(الدر المختار مع رد المحتار۔جلد1۔صفحہ648)</span>ِ</p>
        <p className="new-para">
            مذکورہ جزئی پر یہ اشکال وارد ہوسکتا ہے کہ ان تصاویر میں اگر چہرہ مکمل اعضاء کے ساتھ موجود ہو، تو انہیں منع ہی کیا جائے گا،کیونکہ تصویر چہرے ہی کا نام ہے۔ اگرچہ یہ بات بالکل درست ہے کہ تصویر چہرے ہی کا نام ہے،لیکن فقط چہرے کا وجود حرمتِ تصویر کا سبب نہیں، ورنہ مردے کی یا بہت چھوٹی تصویر بھی دائرہ حرمت میں شامل ہوتی، جبکہ اِس کا استثناء فقہاء سے ثابت ہے،جس کی علت، عدم عبادت ہی ہے کہ تصویروں کو پوجنے والے،ان تصاویر کی عبادت نہیں کرتے،جو مکمل نہ ہوں۔
        </p>
        <p className="new-para">
            مفتی محمد امین شامی علیہ الرحمہ مذکورہ جزئی کی وضاحت کرتے ہوئے فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">تعمیم بعد تخصیص وھل مثل ذلک ما لوکانت مثقوبۃ البطن مثلا والظاھر انہ لو کان الثقب کبیرا یظھر بہ نقصھا فنعم والا فلا</span>۔
            (چہرہ اور سر کٹی تصویر کو)خاص طور پر ذکر کرنے کے بعد، عام حکم بیان کیا گیا (کہ ہر وہ عضو،جس کے بغیر زندگی ممکن نہیں،اس عضو کے بغیر تصاویر بنانا جائز ہے)، تو (سوال پیدا ہوتا ہے کہ) مثلاً ایسی تصویر، جس کے پیٹ میں سوراخ کردیا جائے،تو کیا اسی کی مثل (جائز) ہوگی؟ تو ظاہر یہ ہے کہ اگر سوراخ بہت بڑا ہو، جس کی وجہ سے تصویر میں نقص پیدا ہو، تو جائز ہوگی، ورنہ نہیں۔
            <span className="brown-text arabic">(رد المحتار علی الدر المختار۔جلد1۔صفحہ649)</span>ِ</p>
        <p className="new-para">
            معلوم ہوا کہ تصاویر میں چہرے کا وجود،اسی وقت حرمت کا سبب بنے گا،جب وہ تصویر بے عیب ہو۔ورنہ معبودانِ باطل کی تصاویر کو پوجنے والے عیب دار تصویروں کی عبادت نہیں کرتے اور اپنے زعمِ فاسد میں باطل خدا کو بے عیب دیکھنا چاہتے ہیں۔ جس سے صاف ظاہر ہے کہ عبادت کرنے والوں کے پیشِ نظر صرف چہرہ نہیں،بلکہ تصویر کا بے عیب نہ ہونا مقصود ہے۔اسی سے سبب سے ماضی قریب کے فقہاء نے بھی نصف سینے تک تصاویر بنانے کی اجازت مرحمت فرمائی ہے، کیونکہ نصف سینے کے ساتھ زندگی کا تصور ممکن نہیں، جو یقینا عیب کی طرف اشارہ ہے۔
        </p>
        <p className="new-para">
            مفتی نور اللہ نعیمی بصیر پوری رحمۃ اللہ علیہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            حج کے لیے عازمِ حج کے پورے جسم کا فوٹو ضروری نہیں، بلکہ چہرہ یا قدرے زائد کا فوٹو حکومت نے بعض مصالحِ انتظامیہ کے لیے ضروری قراردیا ہے۔ چنانچہ عموماً پاسپورٹوں پر ایسے ہی فوٹو چسپاں کئے جاتے ہیں، جو نصف سینہ تک کے ہوتے ہیں، حالانکہ انسان نصف سینہ یا سینہ کے نیچے سے کاٹ دیا جائے، تو زندہ نہیں رہ سکتا۔لہذا یہ فوٹو ایسے جسم کا فوٹو ہوگا، جو شجر و حجر کی طرح بے جان ہے، تو جائز ہوگا۔۔۔۔ہاں یہ بھی ضروری ہے کہ بلاضرورت فوٹو نہ کھنچوائے جائیں، اگر چہ شجر و حجر کے ہوں۔ علماءِ کرام کو لہو و لعب پسند نہیں۔
            <span className="brown-text urdu">(فتاوی نوریہ۔جلد2۔صفحہ302)</span>ِ</p>
        <p className="new-para">
            علامہ غلام رسول سعیدی علیہ الرحمہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            بعض تمدنی، عمرانی اور معاشی امور کے لیے فوٹو ناگزیر ہے، مثلاً شناختی کارڈ، پاسپورٹ، ویزا، ڈومی سائل، امتحانی فارم، ڈرائیونگ لائسنس اور اس نوع کے دوسرے امور میں فوٹو کی لازمی ضرورت ہوتی ہے اور اللہ تعالٰی اور رسول پاک صلی اللہ علیہ وسلم نے دین میں تنگی نہیں رکھی۔۔۔اسلام میں جاندار چیزوں کی تصاویر بنانے کی ممانعت ہے اور بے جان چیزوں کی تصویر بنانے کی اجازت ہے۔ اس لیے انسان کی صرف سینے تک کی تصویر بنانا جائز ہے، کیونکہ کوئی انسان بغیر پیٹ کے زندہ نہیں رہ سکتا اور جن تمدنی اور معاشی امور میں تصویر کی ضرورت پڑتی ہے، ان میں اس قسم کی آدھی تصویر ہی کی ضرورت ہوتی ہے۔ اس لیے اس قسم کی ضروریات میں بغیر پیٹ کے سینہ تک کی آدھی تصویر کھنچوانا جائز ہے۔ البتہ شوقیہ فوٹو گرافی مکروہ ہے اور تعظیم و تکریم کے لیے فوٹو کھنچوانا ناجائز و حرام ہے۔
            <span className="brown-text urdu">(شرح صحیح مسلم۔جلد6۔صفحہ471)</span>ِ</p>
        <p className="new-para">
            خلاصہء کلام یہ ہے کہ شریعتِ طاہرہ میں فقط جانداروں کی ایسی تصاویر بنانا ممنوع و ناجائز ہے،جن میں مکمل چہرہ واضح طور پر، جسم کے ایسے تمام اعضاء کے ساتھ موجود ہو، جو وجودِ حیات کے لیے ضروری ہیں۔ نیز یہ تصاویر اتنی بڑی ہوں کہ دور سے دیکھیں، تو اعضاء کی تفصیل بآسانی معلوم ہوسکے۔
        </p>
        <p className="new-para">
            جبکہ انتہائی چھوٹی تصویریں،جو زمین پر رکھیں،تو کھڑے ہو کر دیکھنے والے پر اعضاءِ جسمانی ظاہر نہ ہوں یا ایسے اعضاء کے بغیر ہوں، جو حیات کے لیے ضروری ہیں یا بے جان اشیاء کی ہوں، تو جائز ہیں۔ نیز ایسی چیزوں کی تصویر جن کا خارج میں کوئی وجود ہی نہ ہو، مثلاً ٹماٹر کے اعضاء بنا کر کوئی کارٹون تیار کرلیا جائے، تو یہ بھی جائز ہے کہ ان کی عبادت و پرستش نہیں کی جاتی۔ البتہ جواز کا حکم فقط صحیح مصلحتوں کے ساتھ مقید ہے،جبکہ شوقیہ تصاویر مکروہ اور تعظیم کی نیت سے ہو،تو ان صورتوں میں بھی تصویر کو حرام ہی قرار دیا جائے گا۔
        </p>
    </>,
    answer_02: <>
        <p className="new-para">
            تعلیماتِ شرع کے مطابق تحائف و ہدایا کا لین دین باہمی محبت و الفت کا سبب ہے۔ جیساکہ
        </p>

        <p className="new-para">
            نبی مکرم ﷺ نے فرمایا،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                تهادوا تحابوا
            </span>
            ۔یعنی ایک دوسرے کو ہدیہ دیا کرو کہ باہمی محبت میں اضافہ ہوگا۔

            <span className="arabic brown-text">(مؤطا امام مالک:1896)</span></p>
        <p className="new-para">
            جبکہ اس کے مقابلے میں کسی کو تحفہ دے کر واپس لینا انتہائی ناپسندیدہ عمل ہے۔ آقائے دو جہاں ﷺ نے بہت ہی سخت الفاظ میں اس کی مذمت فرمائی ہے۔چنانچہ
        </p>
        <p className="new-para">
            رسول اللہ ﷺ كا فرمان ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                العائد في هبته كالكلب يقيء ثم يعود في قيئه
            </span>
            ۔ یعنی ہبہ واپس لینےوالا ایسے كتے كی مانند ہے، جو قے كرتا ہے، پھر اپنی قے كھا لیتا ہے۔
            <span className="arabic brown-text">(الصحیح للبخاری:2589)</span>
        </p>
        <p className="new-para">
            لیکن یہ بات ذہن نشین رہے کہ شریعت مطہرہ میں تحفے کے ذریعے سامنے والے کی ملكیت تبھی متحقق ہوتی ہے، جب تمام تر تصرفات و اختیارات كے ساتھ قبضہ فراہم كیا جائے۔لہذا جب تک قبضہ میں نہیں دیا جائے گا، تب تک واھب، یعنی تحفہ دینے والے كو رجوع كا اختیار حاصل ہوتا ہے۔جیسا کہ
        </p>
        <p className="new-para">
            علامہ ابن عابدین شامی رحمہ اللہ لكھتے ہیں كہ
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">قال في التتارخانية قد ذكرنا أن الهبة لا تتم إلا بالقبض</span>۔
            تتارخانیہ میں ہے كہ بےشک ہبہ، قبضہ كے بغیر مكمل نہیں ہوتا۔
            <span className="brown-text arabic">(رد المحتار علی الدر المختار۔جلد5۔صفحہ148)</span>ِ
        </p>
        <p className="new-para">
            اور جب کسی کو قبضہ دے دیا جائے، تو واپسی کا مطالبہ اگرچہ ناپسندیدہ ہے، لیکن اگر کوئی واپس لینا ہی چاہے، تو کچھ شرائط کے ساتھ اس کا یہ مطالبہ پورا کیا جائے گا۔جس میں سے پہلی شرط یہ ہے کہ دونوں افراد باہم راضی ہوں یا قاضیء وقت واہب کے حق میں فیصلہ سنا کر،اس کی چیز واپس دلا دے۔جیسا کہ
        </p>
        <p className="new-para">
            علامہ علاؤ الدین حصکفی رحمہ اللہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                ولا يصح الرجوع إلا بتراضيهما أو بحكم الحاكم
            </span>۔
            ۔یعنی رجوع فقط باہمی رضامندی یا حاكم كے حكم كی بناء پر ہی درست ہوگا۔
            <span className="brown-text arabic">(الدرالمختارمع ردالمحتار۔ جلد5۔صفحہ704)</span>ِ</p>
        <p className="new-para">
            اس کے علاوہ سات صورتیں مانع رجوع ہیں، یعنی ان صورتوں میں باہمی رضامندی کے ساتھ بھی واپسی درست نہیں اور نہ ہی قاضی ان صورتوں میں واپسی کا حکم دے گا۔جیسا کہ
        </p>
        <p className="new-para">
            علامہ ابن عابدین شامی لكھتے ہیں كہ
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                فقال:منع الرجوع من الواهب سبعة ... فزيادة موصولة موت عوض وخروجها عن ملك موهوب له ... زوجية قرب هلاك قد عرض
            </span>۔
            یعنی علامہ شیخ الاسلام محی الدین رحمہ اللہ فرماتے ہیں كہ واھب سے موانع رجوع سات ہیں،زیادت متصلہ(یعنی ہبہ کی جانے والی چیز میں ایسا اضافہ، جو متصل ہو، مثلاً زمین ہبہ كی، سامنے والے نے اس پرمكان بنا لیا)۔۔یا۔۔(تحفہ لینے یا دینے والے کی)موت۔۔یا۔۔(تحفہ لینے والا،دینے والے کو کوئی)عوض(دے دے)۔۔یا۔۔ہبہ (کی جانے والی چیز)موھوب لہ (یعنی تحفہ لینے والے) كی ملكیت سے خارج ہوجائے(مثلاً اس نے وہ شیء کسی اور کو فروخت كردی)۔۔یا۔۔زوجیت(یعنی میاں بیوی میں سے كسی ایك نے دوسرے كو تحفہ دیا، تو اب رجوع نہیں كرسكتے، جبکہ نکاح کے بعد دیا ہو۔جبکہ قبل نكاح كوئی چیز ہبہ كی، توبعدِ نكاح  قاضی كے فیصلے یا باہمی رضامندی سے رجوع كرسكتا ہے)۔۔یا۔۔قرابت(یعنی ذی رحم محارم ایک دوسرے کو تحفہ دیں، تو واپسی نہیں ہوسکتی۔۔یا۔۔ ہبہ کی جانے والی چیز کی)ہلاكت(بھی ایسا سبب ہے کہ رجوع نہیں کیا جاسکتا)۔
            <span className="brown-text arabic">(رد المحتار علی الدرالمختار۔جلد5۔صفحہ699)</span>ِ</p>
        <p className="new-para">
            اگر مذکورہ امور میں سے کوئی ایک بھی امر پایا جائے،تو ہبہ سے رجوع درست نہیں ہوگا اور ان میں سے کوئی بھی نہ ہو، تو رجوع کیا جاسکتا ہے، لیکن ناپسندیدہ اور بعض علما نے اسے مکروہِ تحریمی بھی قرار دیا ہے۔ لہذا ایسے عمل سے اجتناب میں ہی عافیت ہے۔
        </p>
    </>,
    answer_03: <>
        <p className="new-para">
            کسی بھی مقام پر ملازمت کا مطلب یہ ہے کہ ہم اپنے وقت کا خاص حصہ مستاجر (Employer) کو فروخت کرچکے ہیں۔ جس کے بعد ہم اجیر خاص ہوکر، دو چیزوں، یعنی اپنی موجودگی اور جو کام طے کیا گیا ہے، کے پابند ہوجاتے ہیں اور خاص اس وقت میں مستاجر کی اجازت کے بغیر، اس کی جانب سے کام ہوتے ہوئے، اپنا ذاتی کام نہیں کیا جاسکتا۔ جیسا کہ
        </p>

        <p className="new-para">
            علامہ علاؤ الدین حصکفی رحمہ اللہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                الأجير (الخاص) ويسمى أجير وحد (وهو من يعمل لواحد عملا مؤقتا بالتخصيص ويستحق الأجر بتسليم نفسه في المدة وإن لم يعمل كمن استؤجر شهرا للخدمة ۔۔۔۔۔ وليس للخاص أن يعمل لغيره
            </span>
            ۔یعنی اجیر خاص، جسے اجیر وحد بھی کہتے ہیں، جو کسی ایک فرد کے لیے، خاص وقت طے کرکے کام کرتا ہے۔ یہ اجرت کا مستحق اسی وقت ہوجاتا ہے، جب اس وقت میں (طے شدہ جگہ پر) موجود رہے، اگرچہ اس سے کچھ بھی کام نہ کروایا جائے۔ مثلاً وہ شخص، جسے خدمت کے لیے ایک ماہ کی ملازمت پر رکھا جائے۔ نیز اس خاص وقت میں کسی اور کے لیے (بعوض) کام کرنا جائز نہیں۔

            <span className="arabic brown-text">(الدرالمختار مع رد المحتار۔جلد6۔ صفحہ69)</span>
        </p>
        <p className="new-para">
            مفتی محمد امین شامی علیہ الرحمہ مذکورہ مسئلے کی وضاحت میں فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                بل ولا أن يصلي النافلة
            </span>
            ۔بلکہ (مستاجر کی اجازت نہ ہو،تو اس کی جانب سے کام ہوتے ہوئے)، نفل نماز ادا کرنا بھی جائز نہیں۔
            <span className="arabic brown-text">(رد المحتار علی الدرالمختار۔جلد6۔ صفحہ69)</span>
        </p>
        <p className="new-para">
            لہذا جب نوافل پڑھنے تک کی اجازت نہیں،تو دیگر کام مستاجر کی اجازت کے بغیر کرنا،بدرجۂ اولیٰ منع ہوگا۔نیزمذکورہ بالامسئلے سے یہ بھی واضح ہوگیا کہ یہ وقت چونکہ ہم کسی ایک شخص کو پہلے ہی فروخت کرچکے ہیں،تو اب کسی اورکویہی وقت فروخت کرنے کی اجازت نہیں۔لہذا اگر مستاجراجازت بھی دے دے،تواس وقت میں پیسوں کے بدلے کسی اورکا کام کرنا جائز نہیں کہ اس کی ممانعت خاص شریعتِ مطہرہ کا حق ہے۔جیساکہ
        </p>
        <p className="new-para">
            حضرت ابوہریرہ رضی اللہ عنہ سے مروی ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                نهى رسول الله صلى الله عليه وسلم عن بيعتين في بيعة
            </span>
            ۔یعنی اللہ کے رسول ﷺنے ایک بیع میں دوسری بیع شامل کرنے سے منع فرمایا ہے۔
            <span className="arabic brown-text">(السنن للترمذی۔حدیث:1231) </span>
        </p>
        <p className="new-para">
            البتہ یہ تفصیل بھی پیشِ نظر رہنی چاہیئے کہ معاملات میں عرف و ماحول کا بہت دخل ہوتا ہے۔ چنانچہ مستاجر کی جانب سے اجازت کی بھی دو صورتیں ممکن ہیں، پہلی صراحۃً اور دوسری عرف و ماحول کے ذریعے۔ چنانچہ اگر مستاجر نے اپنی جانب سے عرف کے خلاف کوئی بات نہ کہی ہو، تو پھر عام طور پر تھوڑا بہت موبائل اس انداز سے استعمال کرنا کہ کام پر حرج واقع نہ ہو، تو اس میں کوئی قباحت نہیں۔ فقہاءِ کرام کا مشہور ضابطہ ہے کہ <span className="arabic brown-text">الثابت بالعرف کالثابت بالنص</span>۔یعنی جو چیز عرف سے ثابت ہو، وہ ایسے ہی ہے، جیسے صراحۃً کہی گئی ہو۔
        </p>
        <p className="new-para">
            البتہ عرف کے خلاف یا اس انداز سے موبائل وغیرہ کا ذاتی استعمال منع ہوگا، جس سے طے شدہ کام متاثر ہو اور اگر مستاجر نے از خود ہی اس حوالے سے کوئی شرائط مقرر کی ہوں، تو اب عرف کے بجائے، ان شرائط کا لحاظ کرنا ہوگا اور اس میں کوتاہی کے سبب، جتنا وقت ضائع ہوا، اس کی تنخواہ کا مستحق نہیں ہوگا۔
        </p>
        <p className="new-para">
            علامہ علاؤ الدین حصکفی رحمہ اللہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">
                لیس للخاص ان یعمل لغیرہ ولو عمل نقص من اجرتہ بقدر ما عمل
            </span>
            ۔یعنی اجیر خاص کو جائز نہیں کہ دوسروں کا کام کرے، اگر کرتا ہے، تو اتنا اس کی اجرت سے کاٹا جائے گا۔
            <span className="arabic brown-text">(الدرالمختار مع رد المحتار۔جلد6۔ صفحہ69)</span>
        </p>
        <p className="new-para">
            اعلٰی حضرت امام احمد رضا خان رحمة  اللہ علیہ فرماتے ہیں،
        </p>
        <p className="reference-gap">
            " اجیر خاص پر وقتِ مقررہ معہود میں تسلیمِ نفس لازم ہے اور اسی سے وہ اجرت کا مستحق ہوتا ہے، اگرچہ (مستاجر کی جانب سے) کام نہ ہو۔۔۔۔ بہر حال جس قدر تسلیمِ نفس میں کمی کی ہے اتنی تنخواہ وضع ہوگی۔
            <span className="arabic brown-text">(فتاوی رضویہ۔جلد19۔صفحہ506)</span>
        </p>
        <p className="new-para">
            لہذااگرکوئی اس طرح کا عمل کرچکاہے،توحساب کرنے کے بعداپنی اجرت واپس لوٹادے یا اگرلوٹانا ممکن نہ ہو،تواتنی ہی اجرت بلانیتِ ثواب صدقہ کرنی ہوگی۔نیز اگر مستاجر کی جانب سے کوئی کام نہ دیا جائے، تو فراغت کے اوقات میں طے شدہ مقام پر رہتے ہوئے، اپنا ذاتی کام کرنے میں حرج نہیں۔
        </p>
    </>,
    answer_04: <>
        <p className="new-para">
            عند الشرع عورت کا بلا ضرورت نقاب میں نماز پڑھنامکروہِ تحریمی اور ایسی نماز کا دہرانا لازم ہے۔
        </p>
        <p className="new-para">
            حضرت جابر بن زید رضی اللہ عنہ سے  مروی ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">أنه كره أن  وهي منتقبة أو تطوف وهي منتقبة</span> ۔عورت کا نقاب کی حالت میں نماز پڑھنا اور طواف کرنا  مکروہ ہے۔
            <span className="brown-text arabic">(المصنف لابن أبي شيبة:7317)</span>
        </p>
        <p className="new-para">
            فتاوی الھندیہ میں ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">ويكره التلثم وهو تغطية الأنف والفم في الصلاة</span>
            ۔نماز میں اس طرح چہرہ ڈھانپنا  کہ منہ اور ناک چھپ جائیں، مکروہ ہے۔
            <span className="arabic brown-text">(جلد1۔ صفحہ107)</span>
        </p>
        <p className="new-para">
            لیکن اگر کہیں ایسی صورت بن جائے کہ غیر مردوں کا ہجوم ہو اور اس کے علاوہ دوسری کوئی جگہ بھی میسر نہ ہو، نماز کا وقت بھی نکلا جا رہا ہو اور بلا نقاب نماز پڑھنے میں فتنے کا اندیشہ ہو، تو اس صورت میں نقاب کے ساتھ نماز پڑھنے کی اجازت ہے۔ کیونکہ پردے کا حکم،  اس حکم کی بہ نسبت زیادہ سخت ہے۔
        </p>
        <p className="new-para">
            ارشاد باری تعالی ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">يَاأَيُّهَا النَّبِيُّ قُلْ لِأَزْوَاجِكَ وَبَنَاتِكَ وَنِسَاءِ الْمُؤْمِنِينَ يُدْنِينَ عَلَيْهِنَّ مِنْ جَلَابِيبِهِنَّ ذَلِكَ أَدْنَى أَنْ يُعْرَفْنَ فَلَا يُؤْذَيْنَ وَكَانَ اللَّهُ غَفُورًا رَحِيمًا</span>
            ۔اے نبی!اپنی بیویوں اور اپنی صاحبزادیو ں اور مسلمانوں کی عورتوں سے فرمادو کہ اپنی چادروں کا ایک حصہ اپنے او پر ڈالے رکھیں ، یہ اس سے زیادہ نزدیک ہے کہ وہ پہچانی جائیں، تو انہیں ستایا نہ جائے اور اللہ بخشنے والا مہربان ہے۔
            <span className="arabic brown-text">(الاُحزاب:59)</span>
        </p>
        <p className="new-para">
            حضرت عائشہ رضی اللہ عنھا فرماتی ہیں،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text"> كنا مع النبي صلى الله عليه وسلم ونحن محرمون فإذا لقينا الراكب أسدلنا ثيابنا من فوق رءوسنا فإذا جاوزنا رفعناها</span>
            ۔ہم لوگ نبی کریمﷺ کے ساتھ  احرام کی حالت میں تھے۔ جب ہمیں کوئی سوار ملتا، تو ہم اپنا کپڑا اپنے سروں کے اوپر سے لٹکا لیتے اور جب سوار آگے بڑھ جاتا، تو ہم اسے اٹھا لیتے ۔
            <span className="arabic brown-text">(السنن لابن ماجه:2935)</span>
        </p>
        <p className="new-para">
            معلوم ہوا کہ ازواجِ مطہرات اُس دور میں حالتِ احرام کے ہوتے ہوئے بھی، فتنے کے باعث، چہرے کو کسی آڑ سے چھپانے کا اہتمام کیا کرتی تھیں کہ پردے کا اہتمام زیادہ ضروری ہے۔
        </p>
        <p className="new-para">
            فقہ کا مشہور قاعدہ ہے،
        </p>
        <p className="reference-gap">
            <span className="arabic brown-text">إذا اجتمع مكروهان أو محظوران أو ضرران ولم يمكن الخروج عنهما
                وجب ارتكاب أخفهما</span>
            ۔جب دو مکروہات، محظورات یا ضرر جمع ہوجائیں اور ان دونوں سے بچنے کا کوئی راستہ نہ ہو، توان میں سے اخف یعنی کم ضرر والے (ممنوع کام کا) کا ارتکاب لازم ہے۔
            <span className="arabic brown-text">(القواعد الفقهية وتطبيقاتها في المذاهب الأربعة۔جلد 1۔صفحہ 226)</span>
        </p>
        <p className="new-para">
            چنانچہ فی زمانہ جب فتنوں کا اندیشہ اُس دور کے مقابلے میں کئی گنا زیادہ ہے کہ نقاب کرنے والی خاتون نے چہرہ کھولا، تو بطورِ خاص اسے دیکھا جائے گا، بلکہ ممکن ہے، کوئی موبائل سے تصویر بنا کر اس کا غلط استعمال کرے، تو ایسے فتنے کے مقابلے میں اپنا چہرہ چھپا کر نماز پڑھنے کی کراہت بہت کم ہے۔ لہذا اس کی اجازت ہوگی اور اس صورت میں نماز لوٹانے کی حاجت بھی نہیں۔
        </p>
    </>,
    answer_05: <>
    <p className="new-para">
        پرائز بانڈ کی دو قسمیں رائج شدہ ہیں اور دونوں کے احکام مختلف ہیں۔ ایک وہ قسم ہے، جس پر کچھ مخصوص مدت میں متعین رقم دی جاتی ہے اور ساتھ ہی انعامی رقم بھی قرعہ اندازی کے ذریعے دی جاتی ہے، اِسے پریمیئم پرائز بانڈ کہتے ہیں، ان کا نفع ناجائز و ممنوع ہے۔ جبکہ دوسری قسم جن میں کوئی مخصوص رقم نہیں دی جاتی، بلکہ صرف قرعہ اندازی کے ذریعے انعام دیا جاتا ہے، ان سے حاصل ہونے والے انعام میں کوئی قباحت نہیں۔
    </p>
    <p className="new-para">
پریمیئم پرائز بانڈز کی ممانعت اس وجہ سے ہے کہ یہ پرائز بانڈز حقیقت میں مال نہیں، بلکہ اکاوؑنٹ میں جمع شدہ رقم، جو در حقیقت ہماری جانب سے دیا جانے والا قرضہ ہے، اس کی رسید ہیں، جس کی واضح دلیل، ان بانڈز پر لکھے ہوئے یہ الفاظ 
<span className="brown-text">"اس بانڈ کے گم ہونے، چوری ہونے یا جل جانے پر بھی کوئی خطرہ نہیں"</span>
، ہیں۔ اسی سبب سے جس کے نام پر پرائز بانڈ جاری ہوا ہے، وہ متبادل بانڈ بھی نکلوا سکتا ہے۔ لہذا اس بانڈ کی حیثیت قرض کی رسید کی ہوئی، کیونکہ اگر یہ خود مال ہوتا، تو دیگر بانڈ اور کرنسی کی مثل جل جانے، گم ہونے یا چوری ہوجانے کی صورت میں متبادل نہیں ملتا اور مالک کا نقصان ہوتا، جبکہ ایسا نہیں ہے۔ معلوم ہوا کہ اس کا فائدہ قرض کے نتیجے میں مل رہا ہے اور وہ بھی ایسا نفع، جو مشروط ہے اور یہی سود کی حقیقت ہے۔
    </p>
    <p className="new-para">
        کنز العمال میں ہے،
    </p>
    <p className="reference-gap">
        <span className="arabic brown-text">کل قرض جر منفعۃ فھو</span>۔ ہر قرض، جس کے ذریعے سے منفعت حاصل کی جائے، وہ سود ہے۔
        <span className="brown-text arabic">(کنز العمال۔جلد6۔صفحہ238)</span>
    </p>
    <p className="new-para">
        اور قرآن مجید میں ہے،
    </p>
    <p className="reference-gap">
        <span className="arabic brown-text">وَ اَحَلَّ اللّٰهُ الْبَیْعَ وَ حَرَّمَ الرِّبٰواؕ</span>
        ۔اور اللہ تعالیٰ نے بیع (خرید و فروخت) کو حلال کیا اور سود کو حرام کیا۔
        <span className="arabic brown-text">(البقرۃ:275)</span>
    </p>
    <p className="new-para">
        البتہ! دوسری قسم، جو عام طور پر رائج ہے، اس کی خرید و فروخت، اس کا انعام جائز ہے کہ یہ بانڈز مال کی حیثیت رکھتے ہیں۔ان کے گم ہوجانے، جل جانے، چوری ہوجانے کی صورت میں مالک کو نقصان ہوتا ہے اور اس بانڈ پر حاصل ہونے والی انعامی رقم نہ تو سود کی حیثیت رکھتی ہے اور نہ جوا کی، بلکہ حکومتِ پاکستان کی جانب سے بطورِ انعام ہوتی ہے۔
    </p>
    <p className="new-para">
        جوا کے حکم میں اس لیے نہیں کہ خریدار نے جو پرائز بانڈ خریدا ہے، اس میں اس کی ملکیت زائل نہیں ہوتی اور نہ ہی اس کی رقم ڈوبنے کا ان میں کوئی اندیشہ ہوتا ہے۔ بلکہ مالک جب چاہے، اس کو اپنی ضرورت کے وقت بیچ سکتا ہے۔
    </p>
    <p className="new-para">
        معجم الفقہاء میں جوا کی تعریف یوں بیان کی گئی ہے،
    </p>
    <p className="reference-gap">
        <span className="arabic brown-text">تعلیق الملک علی الخطر والمال من الجانبین</span>
        ۔ یعنی اپنے مال کو خطرے میں اس حال میں ڈالنا کہ دونوں جانب مال ہو۔
        <span className="arabic brown-text">(صفحہ 368)</span>
    </p>
    <p className="new-para">
        اسی طرح سود کے حکم میں بھی نہیں، اس لیے کہ انعام ملنے والا نفع، عقد میں مشروط نہیں ہوتا، اسی وجہ سے انعام نہ ملنے کی صورت میں، اس کا مطالبہ بھی نہیں کیا جاسکتا، جبکہ سود، عقدِ معاوضہ میں مشروط، اس زیادتی کو کہتے ہیں، جو عوض سے خالی ہو۔
    </p>
    <p className="new-para">
        ہدایہ میں ہے،
    </p>
    <p className="reference-gap">
        <span className="arabic brown-text">ان الربا ھو الفضل المستحق لاحد المتعاقدین فی المعاوضۃ الخالی عن عوض شرط فیہ</span>
        ۔یعنی سود متعاقدین میں سے کسی ایک کے لیے عقد سے ثابت ہونے والی، اس مشروط زیادتی کو کہتے ہیں، جو عوض سے خالی ہو۔
        <span className="arabic brown-text">(الھدایہ۔جلد3۔صفحہ61)</span>
    </p>
</>
}