export const CONSTANTS = {
    GENERAL: {
        HOME_TXT: "Home",
        DARULIFTA_TXT: "Darulifta",
        DARULIFTA_SECTION_INTRO: "In this section you will find the detailed answer of your questions with authentic references.",
        ARTICLES_TXT: "Articles",
        ARTICLES_SECTION_INTRO: "Knowledge based articles on different topics written by our reliable scholars are added in this section.",
        VIDEOS_TXT: "Videos",
        VIDEOS_SECTION_INTRO: "You will find Quranic lectures, Dars e Hadees, podcasts, current issues, Q&A sessions and videos on various other topics in this section.",
        CONTACT_TXT: "Contact",
        ABOUT_TXT: "About",
        SPONSOR_TXT: "Sponsors",
        WEBSITE_NAME: "Al Inshirah",
        WEBSITE_SLOGAN: "Hope for the Muslim Youth",
        CLICK_HERE: "Click to enter",
        WHO_WE_ARE: "Who we are ?",
        CATEGORY_TXT: "Categories",
        NO_FATWA_URDU: "اس عنوان کے تحت فی الحال کوئی فتوی موجود نہیں",
        NO_VIDEO_TXT: "Currently there isn't any video under this category",
        READ_MORE: "مزید پڑھیئے۔۔۔"
    },
    CATEGORIES: {
        FATAWA_CATEGORIES: {
            ALL_FATAWA: "تمام فتاوی",
            AQAID_TXT: "عقائد",
            NAMAZ_TXT: "نماز",
            ROZA_TXT: "روزہ",
            ZAKAT_TXT: "زکوۃ",
            HAJJ_TXT: "حج",
            NIKAH_TXT: "نکاح",
            TALAQ_TXT: "طلاق",
            BUSINESS_TXT: "کاروبار",
            JOB_TXT: "نوکری",
            HALAL_HARAM_TXT: "حلال و حرام",
            HIBA_TXT: "ہبہ",
            MISCELLENOUS_TXT: "متفرقات",
        },
        VIDEOS_CATEGORIES: {
            ALL_VIDEOS: "All videos",
            PODCASTS: "Podcasts",
            QURAN: "Quran",
            SHORT_CLIPS:"Short messages"
        }
    },
    FATAWA_WRITERS: {
        MADANI_RAZA: "مفتی محمد مدنی رضا",
        MOIZ: "معلم عبد المعز",
        FAHAD_RAZA: "علامہ محمد فہد رضا",
        MUHAMMAD_MOIZ: "علامہ محمد معز",
        NAEEM: "علامہ محمد نعیم"
    }
}