import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";

export default function ArticlesView(){
    return (
        <>
            <Header />
            <div className="insh-container" style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "50vh"}}>
                <h3>Page is under development. Sorry for inconvenience.</h3>
                <p>We will be available soon. ان شاء اللہ</p>
                <a href="/about">About us</a>
            </div>
            <Footer/>
        </>
    )
}