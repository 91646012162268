import "./Darulifta.css"
import { CONSTANTS } from "../../../Helpers/Constants"
export default function FatawaList({ propsList }) {
    const {
        fatawaList,
        categories,
        categoryHandler,
        viewFatwaHandler
    } = propsList

    return (
        <div className="inner-page-template insh-wrapper insh-container">
            <div className="template-body">
                <div className="body-header">
                    <h1>{CONSTANTS.GENERAL.DARULIFTA_TXT}</h1>
                    <p>{CONSTANTS.GENERAL.DARULIFTA_SECTION_INTRO}</p>
                </div>
                <div className="content-filter">
                    <select className="urdu" onChange={(e) => categoryHandler(e.target.value)}>
                        {Object.keys(categories).map((category, index) => {
                            return (
                                <option value={categories[category]} key={categories[category] + index}>{categories[category]}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={fatawaList.length > 0 && "body-content"}>
                    {fatawaList.length > 0 ? fatawaList.map((data, index) => {
                        return (
                            <div key={data.heading + index} className="urdu content-card">
                                <div className="content-card-header">
                                    <h4>{data.heading}</h4>
                                    <p>Category: {data.category}</p>
                                </div>
                                <div className="content-card-body">
                                    <p className="fatwa-description">{data.description}</p>
                                    <p onClick={() => viewFatwaHandler(index)} className="content-link">{CONSTANTS.GENERAL.READ_MORE}</p>
                                </div>
                            </div>
                        )
                    })
                        :
                        <div className="urdu no-fatwa">{CONSTANTS.GENERAL.NO_FATWA_URDU}</div>
                    }
                </div>
            </div>
        </div>
    )
}