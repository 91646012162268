import Home from "./Screens/Home";
import "./Assets/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Screens/About";
import Darulifta from "./Screens/Darulifta";
import Videos from "./Screens/Videos";
import Articles from "./Screens/Articles";
import Contact from "./Screens/Contact";
import FatwaTemplate from "./Components/FatwaTemplate";
import FatawaProvider from "./Context/FatawaContext";
import PathNotFound from "./Screens/PathNotFound";

function App() {
  return (
    <BrowserRouter>
      <FatawaProvider>
        <Routes>
          <Route path="*" element={<PathNotFound />}/>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/darulifta" element={<Darulifta />} />
          <Route exact path="/darulifta/:id" element={<FatwaTemplate />} />
          <Route exact path="/videos" element={<Videos />} />
          <Route exact path="/articles" element={<Articles />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </FatawaProvider>
    </BrowserRouter>

  );
}

export default App;
