import { CONSTANTS } from "./Constants"
import daruliftaFeatureImage from "../Assets/Images/Iftaa.png";
import articlesFeatureImage from "../Assets/Images/articles.png";
import videosFeatureImage from "../Assets/Images/videos.png";


export const HomePageOptions = [
    {
        image: daruliftaFeatureImage,
        heading: CONSTANTS.GENERAL.DARULIFTA_TXT,
        description: CONSTANTS.GENERAL.DARULIFTA_SECTION_INTRO,
        link: "/darulifta"
    },
    {
        image: articlesFeatureImage,
        heading: CONSTANTS.GENERAL.ARTICLES_TXT,
        description: CONSTANTS.GENERAL.ARTICLES_SECTION_INTRO,
        link: "/articles"
    },
    {
        image: videosFeatureImage,
        heading: CONSTANTS.GENERAL.VIDEOS_TXT,
        description: CONSTANTS.GENERAL.VIDEOS_SECTION_INTRO,
        link: "/videos"
    },
]