import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import FatawaList from "./FatawaList";

export default function DaruliftaView({propsList}) {
    return (
        <>
            <Header />
            <FatawaList
                propsList={propsList}
            />
            <Footer/>
        </>
    )
}