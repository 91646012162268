import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import AboutDetails from "./AboutDetails";

export default function AboutView() {
    return (
        <>
            <Header />
            <AboutDetails />
            <Footer />
        </>
    )
}