import { CONSTANTS } from "../../../Helpers/Constants"

export default function VideosList({ propsList }) {
    const { categories, categoryHandler, videosList } = propsList
    return (
        <div className="inner-page-template insh-wrapper insh-container">
            <div className="template-body">
                <div className="body-header">
                    <h1>{CONSTANTS.GENERAL.VIDEOS_TXT}</h1>
                    <p>{CONSTANTS.GENERAL.VIDEOS_SECTION_INTRO}</p>
                </div>
                <div className="content-filter">
                    <select onChange={(e) => categoryHandler(e.target.value)}>
                        {Object.keys(categories).map((category, index) => {
                            return (
                                <option value={categories[category]} key={categories[category] + index}>{categories[category]}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={videosList.length > 0 && "body-content"}>
                    {console.log(videosList, "data")}
                    {videosList.length > 0 ? videosList.map((data, index) => {
                        return (
                            <div key={data.heading + index} className="video-content-card">
                                <div className="content-card-header">
                                    {data.video_link}
                                    <p>Category: {data.category}</p>
                                </div>
                            </div>
                        )
                    })
                        :
                        <div className="no-fatwa">{CONSTANTS.GENERAL.NO_VIDEO_TXT}</div>
                    }
                </div>
            </div>
        </div>
    )
}