import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";

export default function ContactView(){
    return (
        <>
            <Header />
            <div className="insh-container" style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "50vh"}}>
                <h3>Page is under development. Sorry for inconvenience.</h3>
                <p>We will be available soon. ان شاء اللہ</p>
                <a href="https://wa.me/923333123775" title="+92 333 3123775">Our WhatsApp Number</a>
            </div>
            <Footer/>
        </>
    )
}