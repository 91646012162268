import { useNavigate } from "react-router-dom"
import Logo from "../../Assets/Images/Logo-White.png"
import { CONSTANTS } from "../../Helpers/Constants"
import "./footer.css"

export default function Footer() {
    const navigate = useNavigate()
    return (
        <div className="insh-footer insh-wrapper">
            <div className="footer-logo-section">
                <div onClick={()=>navigate("/")} className="footer-logo">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="footer-logo-text">
                    <h3>{CONSTANTS.GENERAL.WEBSITE_NAME}</h3>
                    <p>{CONSTANTS.GENERAL.WEBSITE_SLOGAN}</p>
                </div>
            </div>
            <div className="footer-text-section">
                <p onClick={()=>navigate("/about")}>{CONSTANTS.GENERAL.ABOUT_TXT}</p>
                <p onClick={()=>navigate("/contact")}>{CONSTANTS.GENERAL.CONTACT_TXT}</p>
            </div>
        </div>
    )
}