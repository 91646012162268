import { CONSTANTS } from "../../../Helpers/Constants";

export default function AboutDetails() {
    return (
        <div className="about-section">
            <div className="about-header">
                <h1>{CONSTANTS.GENERAL.WHO_WE_ARE.toLocaleUpperCase()}</h1>
            </div>
            <div className="about-paragraph">
                <p>Al Inshirah is a team of young scholars working under the supervision of <b>Mufti Muhammad Madani Raza</b>. Our main purpose is to spread the Islamic Teachings among a new generation in a professional way.</p>

                <h2>What are we doing:</h2>
                <ul>
                    <li>
                        We are recording, podcasts with personalities of different fields, short Quranic Lectures in an easy way and other videos on various topics.
                    </li>
                    <li>
                        We have a team of Islamic Jurists who are busy in answering questions in our <a href="https://chat.whatsapp.com/E7QMdO2dVKmH30Q3PXXe8g" target={"_blank"}>
                            Official WhatsApp Group
                        </a> and in writing <i>Fatawa</i> (Islamic Rulings in written with references) for our <a href="/darulifta"><i>Darulifta</i> (Fatawa Centre)</a>.
                    </li>
                    <li>
                        Some of our Islamic Scholars are busy in writing <a href="/articles">articles</a> on Islamic beliefs, Jurisprudence, Personalities, Current Issues and on other important topics.
                    </li>
                    <li>
                        Social Media Team is busy in designing and posting informative posts on our different social media platforms.
                    </li>
                </ul>
                <h2>What are our goals:</h2>
                <ul>
                    <li>
                        Other than social media we are looking forward to held on-site weekly gatherings as <i>Tarbiyah</i> Sessions.
                    </li>
                    <li>
                        Specially for Youth our goal is to build a team of Professional Scholars who can teach <i>Dars e Nizami</i> (scholar course) and other short courses on both online and on-site platforms.
                    </li>
                    <li>
                        Our intention is to build a team of <i>Qurraas</i> (Quran teachers), who can teach Quran professionally, for both on-site and online platforms.
                    </li>
                    <li>
                        Currently we all are working from home with our fully dedication. But due to limited sources, we are facing some difficulties. For that, we are looking for a proper workplace where our team can work in relaxed and professional environment, with all required facilities.
                    </li>
                </ul>
                <h2>What We Need:</h2>
                <p>Indeed ! without your support we can not achieve our goals easily. If you are willing to support our team then we need:</p>
                <ul>
                    <li>Volunteers for social media support.</li>
                    <li>Supporters for helding <i>Tarbiyah</i> sessions.</li>
                    <li>Sponsors for our different courses, podcasts and other sessions. <a href="/sponsors">click here for details.</a></li>
                </ul>
            </div>
        </div>
    )
}