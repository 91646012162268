import { CONSTANTS } from "./Constants";

export const VIDEOS = [
    {
        video_link: < iframe width="100%" height="100%" src="https://www.youtube.com/embed/v-Ia-6GQE1c" title="Podcast | Episode 01 | Tauba or Society Issues | Hasnain Salim Tasbih" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe >,
        ytlink: "https://www.youtube.com/watch?v=v-Ia-6GQE1c&t=11s",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/KQ0iRYcc1pg" title="Podcast | Episode 02 | Awaam Kay Ulama Se Shikwe | Mufti Madani Raza" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=KQ0iRYcc1pg",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/1BX32GfAAhI" title="Podcast | Episode 03 | Walidayn Or Aulad | Abdur Rahman" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=1BX32GfAAhI",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/TtCHAAnUYsc" title="Podcast | Episode 04 | Dosti or Sohbat | Mufti Syed Owais" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=TtCHAAnUYsc",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/VPu-uOgnpA4" title="Podcast | Episode 05 | Tableegh Ya Interference | Kashif Sulaiman" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=VPu-uOgnpA4",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/N22zSS-n7Fs" title="Podcast | Episode 06 | Musalman or Huqooq ul Ibaad | Hasnain Salim" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=N22zSS-n7Fs",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/7FMOcDu2Jbw" title="Podcast | Episode 07 | Rizq e Halal | Mufti Madani Raza" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=7FMOcDu2Jbw",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/rDFaEZMOIrU" title="Podcast | Episode 08 | Career Planning | Abdul Rahman" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=rDFaEZMOIrU",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ilGo-U5Sj4Y" title="Podcast | Episode 09 | Atheism | Ilhaad | Syed Ahsan" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=ilGo-U5Sj4Y",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/VebFicSIFVM" title="Podcast | Episode 10 | Sood or Tijarat | Ahmed Raza" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=VebFicSIFVM",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.PODCASTS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/maWKX2eyoxg" title="Dars e Quran | Lecture 01 | Mufti Madani Raza | Al Inshirah" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=maWKX2eyoxg",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.QURAN
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QRbcjVo4-xs" title="Dars e Quran | Lecture 02 | Mufti Madani Raza | Al Inshirah" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=QRbcjVo4-xs",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.QURAN
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/k5-rtfpT_4E" title="Aqa Karim (صلی اللہ علیہ وسلم) Ne Beta Keh Kar Pukarne Se Kyun Mana Kia | Abdul Rahman | Al Inshirah" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=k5-rtfpT_4E",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.SHORT_CLIPS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zP5tR7dEJ_k" title="Hazrat e Umer Ke Dour Mein Nojawan Ka Khof e Khuda | Abdul Rehman | Al Inshirah" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=zP5tR7dEJ_k",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.SHORT_CLIPS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/os7ES6WJees" title="Apne Ghar Walon Ka Namaz Ka Hukum Deyn | Mufti Madani Raza | Al Inshirah" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=os7ES6WJees",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.SHORT_CLIPS
    },
    {
        video_link: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5LwtcH3St5w" title="Na Shukri Ka Anjaam | Hunain Sufyan | Al Inshirah" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        ytlink: "https://www.youtube.com/watch?v=5LwtcH3St5w&t=28s",
        category: CONSTANTS.CATEGORIES.VIDEOS_CATEGORIES.SHORT_CLIPS
    },
]