import React from "react";
import { FATAWA } from "../../Helpers/Fatawa";
import Header from "../Header";
import "./FatwaTemplate.css";
import fatwaStamp from "../../Assets/Images/fatwa-stamp.png"
import { FatawaContext } from "../../Context/FatawaContext";
import { useParams } from "react-router-dom";
export default function FatwaTemplate() {
    const { currentFatwa, setCurrentFatwa } = React.useContext(FatawaContext)
    let { id } = useParams()

    React.useEffect(() => {
        if (Object.keys(currentFatwa).length === 0) {
            const index = FATAWA.URDU.map(fatwa => fatwa.fatwaId).indexOf(id)
            setCurrentFatwa(FATAWA.URDU[index])
        }
    }, [])
    return (
        <>
            <Header />
            <div className="fatwa-template urdu">
                <div className="fatwa-header">
                    <div className="date teal-text">
                        {currentFatwa.date}
                    </div>
                    <div className="ref-number teal-text">
                        Ref No: {currentFatwa.fatwaId}
                    </div>
                </div>
                <div className="fatwa-body">
                    <div className="fatwa-heading brown-text">
                        {currentFatwa.heading}
                    </div>
                    <div className="fatwa-question">
                        <span className="question-heading arabic brown-text">سوال:</span> {currentFatwa.question} <span className="arabic bayyinu brown-text">۔۔۔بینوا توجروا</span>
                    </div>
                    <div className="answer-heading arabic brown-text">
                        الجواب بتوفیق اللہ الوھاب اللھم اسئلک الحق والصواب
                    </div>
                    <div className="fatwa-answer">
                        {currentFatwa.answer}
                    </div>
                    <div className="fatwa-footer">
                        <div className={`${currentFatwa.verifiedBy === '' && 'hide'} answered-by`}>
                            <p className="arabic brown-text">الجواب صحیح</p>
                            <p className="arabic brown-text"></p>
                            <p className="arabic brown-text">{currentFatwa.verifiedBy}</p>
                        </div>
                        <div className="fatwa-stamp">
                            <img src={fatwaStamp} alt="stamp" />
                        </div>
                        <div className="answered-by">
                            <p className="arabic brown-text">واللہ اعلم بالصواب</p>
                            <p className="arabic brown-text">کـتبــہ</p>
                            <p className="arabic brown-text">{currentFatwa.answeredBy}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="urdu insh-btn back-to-fatawa">
                <a href="/darulifta">پچھلے صفحے پر جائیں</a>
            </div>
        </>
    )
}