import Header from "../../Components/Header";
import Logo from "../../Assets/Images/Logo.png"
import Footer from "../../Components/Footer";

export default function PathNotFound() {
    return (
        <>
            <Header />
            <div className="insh-container" style={{height: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div  style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <img  style={{width: "200px"}} src={Logo} alt="logo"/>
                    <p style={{fontSize: "28pt", textAlign: "center"}}>Error 404 - Page Not Found</p>
                    <a href="/home"  style={{fontSize: "20pt", textAlign: "center", color: "#1da599", textDecoration: "none"}}>Click here to go to the home page</a>
                </div>
            </div>
            <Footer/>
        </>
    )
}