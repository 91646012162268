import { CONSTANTS } from "./Constants"
import { FATAWA_ANSWERS } from "./FatawaAnswers"

export const FATAWA = {
    URDU: [{
        date: "14 شوال المکرٌم 1440ھ بمطابق 17 جون 2019ء",
        fatwaId: "0001",
        heading: "کارٹون اور دیگر چیزوں کی شوقیہ تصاویر بنانے کا حکم",
        question: "کیاشوق کے طورپرکارٹون یا دیگرچیزوں کی اسکیچنگ (Sketching) کرسکتے ہیں؟",
        answer: FATAWA_ANSWERS.answer_01,
        answeredBy: CONSTANTS.FATAWA_WRITERS.MADANI_RAZA,
        verifiedBy: "",
        description: "مسلمان کی یہ شان ہونی چاہیئے کہ اپنی مشغولیت ان امور میں رکھے، جو دینی یا دنیاوی اعتبار سے فائدہ مند ہوں، جبکہ ایسے کاموں سے اجتناب کرنا ہی عظمتِ مسلم ہے،جو بے کار اور بے نفع ہوں۔ البتہ صحیح مصلحت کے پیشِ نظر، نصف سینے تک تصاویر یا کارٹون بنانا جائز ہے۔",
        category: CONSTANTS.CATEGORIES.FATAWA_CATEGORIES.HALAL_HARAM_TXT
    },
    {
        date: "15 ربیع الثانی 1444ھ بمطابق 11 نومبر 2022ء",
        fatwaId: "0002",
        heading: "تحفہ دے کر واپس لینے کا شرعی حکم",
        question: "کیا فرماتے ہیں علماءِ دین و مفتیانِ شرع متین اس مسئلے کے بارے میں کہ GIFT دے کر واپس لینا کیسا ",
        answer: FATAWA_ANSWERS.answer_02,

        answeredBy: CONSTANTS.FATAWA_WRITERS.MOIZ,
        verifiedBy: CONSTANTS.FATAWA_WRITERS.MADANI_RAZA,
        description: "تعلیماتِ شرع کے مطابق تحائف و ہدایا کا لین دین باہمی محبت و الفت کا سبب ہے۔جبکہ اس کے مقابلے میں کسی کو تحفہ دے کر واپس لینا انتہائی ناپسندیدہ عمل ہے۔ آقائے دو جہاں ﷺ نے بہت ہی سخت الفاظ میں اس کی مذمت فرمائی ہے۔",
        category: CONSTANTS.CATEGORIES.FATAWA_CATEGORIES.HIBA_TXT
    },
    {
        date: "23 ربیع الثانی 1444ھ بمطابق 19 نومبر 2022ء",
        fatwaId: "0003",
        heading: "دورانِ ملازمت ذاتی کام یا موبائل وغیرہ استعمال کرنا",
        question: "کیا ایک اجیر(Employee)، دورانِ اجارہ کچھ وقت کے لئے، یعنی 10 سے 15 منٹ کے لئے ذاتی فون کالز یا واٹس ایپ چیٹ وغیرہ کر سکتا ہے؟",
        answer: FATAWA_ANSWERS.answer_03,

        answeredBy: CONSTANTS.FATAWA_WRITERS.FAHAD_RAZA,
        verifiedBy: CONSTANTS.FATAWA_WRITERS.MADANI_RAZA,
        description: "کسی بھی مقام پر ملازمت کا مطلب یہ ہے کہ ہم اپنے وقت کا خاص حصہ مستاجر (Employer) کو فروخت کرچکے ہیں۔ جس کے بعد ہم اجیر خاص ہوکر، دو چیزوں، یعنی اپنی موجودگی اور جو کام طے کیا گیا ہے، کے پابند ہوجاتے ہیں۔",
        category: CONSTANTS.CATEGORIES.FATAWA_CATEGORIES.JOB_TXT
    },
    {
        date: "27 ربیع الثانی 1444ھ بمطابق 23 نومبر 2022ء",
        fatwaId: "0004",
        heading: "غیر مردوں کے درمیان نقاب پہن کر نماز پڑھنا",
        question: "اگر عورت نے کسی ایسی جگہ جہاں غیر محارم کا ہجوم ہے، نقاب میں نماز پڑھ لی، تواب اس پڑھی گئی نماز کو لوٹانا ہوگا یا نہیں؟",
        answer: FATAWA_ANSWERS.answer_04,

        answeredBy: CONSTANTS.FATAWA_WRITERS.MOIZ,
        verifiedBy: CONSTANTS.FATAWA_WRITERS.MADANI_RAZA,
        description: "عند الشرع عورت کا بلا ضرورت نقاب میں نماز پڑھنامکروہِ تحریمی اور ایسی نماز کا دہرانا لازم ہے۔لیکن اگر کہیں ایسی صورت بن جائے کہ غیر مردوں کا ہجوم ہو اور اس کے علاوہ دوسری کوئی جگہ بھی میسر نہ ہو، نماز کا وقت بھی نکلا جا رہا ہو اور بلا نقاب نماز پڑھنے میں فتنے کا اندیشہ ہو، تو ",
        category: CONSTANTS.CATEGORIES.FATAWA_CATEGORIES.NAMAZ_TXT
    },
    {
        date: "11 رجب المرجب 1444ھ بمطابق 03 فروری 2023ء",
        fatwaId: "0005",
        heading: "پرائز بانڈ کے حوالے سے شرعی حکم",
        question: "ہمارے یہاں لوگ پرائز بانڈز خرید کر رکھتے ہیں اور اس سے انہیں انعام وغیرہ کی صورت میں نفع بھی حاصل ہوتا ہے، بعض لوگ اس کو ناجائز کہتے ہیں اور اس کے استعمال سے منع کرتے ہیں۔ برائے کرم اس حوالے سے کوئی واضح حکم بتا دیں کہ اس کے بارے میں شریعت کا کیا حکم ہے؟",
        answer: FATAWA_ANSWERS.answer_05,

        answeredBy: CONSTANTS.FATAWA_WRITERS.NAEEM,
        verifiedBy: CONSTANTS.FATAWA_WRITERS.MADANI_RAZA,
        description: "پرائز بانڈ کی دو قسمیں رائج شدہ ہیں اور دونوں کے احکام مختلف ہیں۔ ایک وہ قسم ہے، جس پر کچھ مخصوص مدت میں متعین رقم دی جاتی ہے اور ساتھ ہی انعامی رقم بھی قرعہ اندازی کے ذریعے دی جاتی ہے، اِسے پریمیئم پرائز بانڈ کہتے ہیں، ان کا نفع ناجائز و ممنوع ہے، جبکہ",
        category: CONSTANTS.CATEGORIES.FATAWA_CATEGORIES.HALAL_HARAM_TXT
    },
    ]
}
