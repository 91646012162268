import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import HomeHeading from "./HomeHeading";
import HomeOptions from "./HomeOptions";

export default function HomeView({ propsList }) {
    const { homeOptions } = propsList

    return (
        <>
            <Header />
            <HomeHeading />
            <div className="body-container">
                <div className="options-section">
                    {
                        homeOptions.map((option, index) => {
                            return (
                                <HomeOptions
                                    key={option.heading + index}
                                    heading={option.heading}
                                    image={option.image}
                                    description={option.description}
                                    link={option.link}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <Footer/>
        </>
    )
}