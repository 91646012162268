import HomeView from "./Views/HomeView";
import "./Views/Home.css";
import { useState } from "react";
import { HomePageOptions } from "../../Helpers/HomePageOptions";

export default function Home(){
    const [homeOptions, setHomeOptions] = useState(HomePageOptions)

    const propsList = {
        homeOptions,
        setHomeOptions
    }
    return (
        <>
            <HomeView
                propsList={propsList}
            />
        </>
    )
}