import React from 'react';

export const FatawaContext = React.createContext();
const Provider = FatawaContext.Provider;

const FatawaProvider = ({ children }) => {
    const [currentFatwa, setCurrentFatwa] = React.useState({});

    const data = {
        currentFatwa,
        setCurrentFatwa
    };
    return (
        <Provider value={data}>{children}</Provider>
    )
};

export default FatawaProvider;