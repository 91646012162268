import React from "react";
import { useNavigate } from "react-router-dom";
import { FatawaContext } from "../../Context/FatawaContext";
import { CONSTANTS } from "../../Helpers/Constants";
import { FATAWA } from "../../Helpers/Fatawa";
import DaruliftaView from "./Views/DaruliftaView";

export default function Darulifta() {
    const { setCurrentFatwa } = React.useContext(FatawaContext)
    const navigate = useNavigate()
    const categories = CONSTANTS.CATEGORIES.FATAWA_CATEGORIES
    const [fatawaList, setFatawaList] = React.useState(FATAWA.URDU)


    const viewFatwaHandler = (index) => {
        setCurrentFatwa(fatawaList[index])
        navigate(fatawaList[index].fatwaId)
    }

    const categoryHandler = (selectedCategory) => {
        if (selectedCategory === CONSTANTS.CATEGORIES.FATAWA_CATEGORIES.ALL_FATAWA) {
            setFatawaList(FATAWA.URDU)
        } else {
            const tempFatawaData = []
            FATAWA.URDU.forEach(fatwa => selectedCategory === fatwa.category && tempFatawaData.push(fatwa))
            setFatawaList(tempFatawaData)
        }
    }
    const propsList = {
        fatawaList,
        categories,
        categoryHandler,
        viewFatwaHandler
    }
    return (
        <>
            <DaruliftaView
                propsList={propsList}
            />
        </>
    )
}