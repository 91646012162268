import { CONSTANTS } from "../../../Helpers/Constants"

export default function HomeOptions({
    heading,
    image,
    description,
    link
}) {
    return (
        <div className="insh-home-options">
            <div className="options-body">
                <div className="options-header">
                    <img src={image} alt="ifta" />
                    <h2>
                        {heading}
                    </h2>
                </div>
                <p>
                    {description}
                </p>
                <div className="redirection-link">
                    <a href={link}>{CONSTANTS.GENERAL.CLICK_HERE}</a>
                </div>
            </div>
        </div>
    )
}